import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag"

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-HX3Y7LRC3L" }
});


new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
