<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <Information />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Information from "./Information";
export default {
  name: "Search",

  components: {
    Information
  }
};
</script>
