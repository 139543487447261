<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mx-auto" elevation="8">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                検索システム
              </v-list-item-title>

              <v-divider></v-divider>

              <v-card-text>
                <p class="additional-message">
                  この検索システムは11月13日（金）17時をもって閉鎖いたします。<br />以降にGoogle
                  Classroomに登録する必要がある人は担任の先生に連絡してください。
                </p>
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Information",
  data: () => ({})
};
</script>
<style scoped>
.additional-message {
  line-height: 1.5rem;
  padding: 1rem 0 0 0;
}
</style>
