<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <h1>Classroom登録</h1>
      <v-spacer></v-spacer>
      <span v-show="!isMobile">日本外国語専門学校</span>
      <span v-show="isMobile">JCFL</span>
    </v-app-bar>

    <v-snackbar bottom :value="showUpdateSnackBar" :timeout="-1" color="info">
      データが更新されました
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="refreshApp" color="primary">
          更新
        </v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <Search />
    </v-main>
  </v-app>
</template>

<script>
import Search from "./components/Search";
export default {
  name: "App",

  components: {
    Search
  },

  data: () => ({
    //
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showUpdateSnackBar() {
      return this.$store.state.showUpdateSnackBar;
    }
  },
  methods: {
    refreshApp() {
      window.location.reload();
      this.$store.commit("SET_SHOW_UPDATE_SNACK_BAR", true);
    }
  }
  // created() {
  //   this.$store.commit("SET_CLASSROOMS", classrooms);
  // }
};
</script>
